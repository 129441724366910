<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí các bài báo về Prep
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogNews"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm mục</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp các bài báo</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tên báo</th>
                    <th scope="col">Logo báo</th>
                    <th scope="col">Tiêu đề hiển thị</th>
                    <th scope="col">Link</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item_news, i) in news">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ item_news.name }}
                        </p>
                      </td>
                      <td>
                        <v-img
                          v-if="item_news.logo"
                          :src="item_news.logo"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ item_news.title }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ item_news.link }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editNews(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteNews(item_news.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!news.length">
                    <tr>
                      <td style="text-align: center" colspan="8">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogNews" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'">Thêm mục</span>
              <span class="headline" v-else>Sửa mục</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogNews = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tên bài báo:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        solo
                        v-model="name_input"
                        dense
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Logo:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <!--                      <ImageCompress :done="getFileLogo"></ImageCompress>-->
                      <b-form-file
                        @change="onChange"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="logo_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="logo_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tiêu đề:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        solo
                        v-model="title_input"
                        dense
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Link:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        solo
                        v-model="link_input"
                        dense
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Avatar:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <b-form-file
                        @change="onChangeAvatar"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="avatar_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="avatar_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditNews(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditNews(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogNews = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="1000px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(item, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1">
                          <span style="color: black">- {{ item.name }}:</span>
                          <span style="color: #3699ff">{{ item.title }}</span>
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortNewQuestion"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
// import ImageCompress from "@/components/image/ImageCompress";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
export default {
  name: "NewsList",
  components: {
    // ImageCompress,
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      news: [],
      dialogNews: false,
      type_form: null,
      name_input: "",
      logo_data: null,
      logo_file: null,
      title_input: "",
      link_input: "",
      news_id: null,
      avatar_data: null,
      avatar_file: null,
      dialogSort: false,
      data_draggable: [],
    };
  },
  created() {
    this.getAllNews();
  },
  methods: {
    async getAllNews() {
      let vm = this;
      await ApiService.get("prep-app/news").then(function (response) {
        if (response.status === 200) {
          vm.news = response.data;
        }
      });
    },
    openDiaLogNews() {
      this.type_form = "add";
      this.name_input = "";
      this.logo_data = null;
      this.logo_file = null;
      this.avatar_data = null;
      this.avatar_file = null;
      this.title_input = "";
      this.link_input = "";
      this.dialogNews = true;
    },
    validateData() {
      let flat = true;
      if (this.name_input === null || this.name_input === "") {
        this.$toasted.error("Hãy nhập name !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.title_input === null || this.title_input === "") {
        this.$toasted.error("Hãy nhập tiêu đề !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEditNews(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("title", this.title_input);
      if (!(this.link_input === null || this.link_input === ""))
        formData.append("link", this.link_input);
      if (this.logo_file != null) formData.append("logo", this.logo_file);
      if (this.avatar_file != null) formData.append("avatar", this.avatar_file);
      this.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/news", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.getAllNews();
              vm.is_call_api = false;
              vm.dialogNews = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post("prep-app/news/" + vm.news_id, formData)
          .then(function (res) {
            if (res.status === 202) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.getAllNews();
              vm.is_call_api = false;
              vm.dialogNews = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    editNews(index) {
      let data = this.news[index];
      this.news_id = data.id;
      this.logo_data = data.logo;
      this.logo_file = null;
      this.avatar_data = data.avatar;
      this.avatar_file = null;
      this.title_input = data.title;
      this.link_input = data.link;
      this.name_input = data.name;
      this.type_form = "update";
      this.dialogNews = true;
    },
    btnDeleteNews(id_news) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/news/" + id_news)
            .then(function (res) {
              if (res.status === 202) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllNews();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    getFileLogo(obj) {
      this.logo_file = obj.file;
      this.logo_data = obj.blob;
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.logo_data = null;
        vm.logo_file = null;
        return;
      }
      vm.logo_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.logo_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onChangeAvatar(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.avatar_data = null;
        vm.avatar_file = null;
        return;
      }
      vm.avatar_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.avatar_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.news));
      this.dialogSort = true;
    },
    sortNewQuestion() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              new_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/news/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllNews();
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
